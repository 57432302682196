import React from 'react';
import { Button, Drawer, Typography, Form, Radio } from 'antd';
import {CreateCheckbox, CreateRadioGroup} from "../../../Helpers/Input/InputCreator";
import { Field } from "redux-form";
import profile from '../../../../const/profile';
import './appOptions.css'
import { drawerWidthOptSig } from '../../../Helpers/utils';

class NewAppOptionsForm extends React.Component {

    render() {
        const widthApp = this.props.dimApp.width
        return (
            (<Drawer styles={{body:{backgroundColor: '#FFFFFF', padding: '0px 20px 82px 20px' }}} open={this.props.visible} placement={'right'}
                width={widthApp < drawerWidthOptSig ? widthApp : drawerWidthOptSig}
                closable={true} onClose={() => this.props.showAppOptions(false)} >
                <Typography rootClassName='text-title size22 color-black' style={{ marginBottom: 22 }}>{profile[this.props.language].appSettings}</Typography>
                <Form rootClassName='text-title size12' onFinish={this.props.handleSubmit(this.props.onSubmit)}>
                    {this.props.netcoPKI && this.props.registration.data && 
                    <Field name="dontShowcreateCertificate"
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {profile[this.props.language].showCertModal}
                    </Field>}
                    <Field
                        rootClassName='margin-top-check'
                        name="dontShowSignAndShare"
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {profile[this.props.language].showShareModal}
                    </Field>
                    <Field
                        rootClassName='margin-top-check'
                        name="defaultTransfer"
                        type={'checkbox'}
                        component={CreateCheckbox}
                        hasFeedback>
                        {profile[this.props.language].defaultTransfer}
                    </Field>
                    {profile[this.props.language].chooseDocsView}
                    <Field
                        rootClassName='margin-top-check'
                        name="filesView"
                        component={CreateRadioGroup}
                        hasFeedback>
                        <Radio value={0}>{profile[this.props.language].chooseDocsViewList}</Radio>
                        <Radio value={1}>{profile[this.props.language].chooseDocsFolderList}</Radio>
                    </Field>
                    <Button type="primary" htmlType="submit" rootClassName='save-button'>
                        {profile[this.props.language].save.toUpperCase()}
                    </Button>
                </Form>
            </Drawer>)
        );
    }
}

export default NewAppOptionsForm;
